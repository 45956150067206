var initFlashMessage = function() {
  var flash = document.querySelector('.js__flash');

  if (flash == null) {
    return
  }

  function hideAndRemove() {
    flash.classList.add('flash-hide');
    setTimeout(function() {
      flash.remove();
    }, 1000)
  }

  var timeout = setTimeout(function() {
    hideAndRemove()
  }, 7000);
  document.querySelector('.flash-close').addEventListener('click', function() {
    clearTimeout(timeout);
    hideAndRemove();
  })
}

var showFlashMessage = function(messageHtml) {
  messageHtml = '<div class="flash js__flash">' + messageHtml + '<span class="close-flash"></span></div>';
  var flashContainer = document.querySelector('.js__flash'),
    elem;
  elem = document.createElement('div');
  elem.innerHTML = messageHtml.trim();

  if (flashContainer.length == 0) {
    document.querySelector('body').appendChild(elem);
  } else {
    flashContainer.innerHTML = elem;
  }

  initFlashMessage();
}

document.addEventListener('DOMContentLoaded', function() {
  initFlashMessage();
})
